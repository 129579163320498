import React, { useCallback } from "react";
import PrintableContent from "./PrintableContent";
import html2canvas from "html2canvas";
import { useLocation } from 'react-router-dom';
const PrintPage = ({ barcode = "", text = "Here Is Your Visitor Card" }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const qValue = queryParams.get('q'); // Gets the value of 'q'
  const handlePrint = () => {
    const printContents =
      document.getElementById("printable-content").innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(); // To refresh the page after printing
  };
  const handleScreenshot = useCallback(() => {
    const body = document.body;

    html2canvas(body).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = imgData;
      link.download = "screenshot.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }, []);

  return (
    <div>
      <PrintableContent barcode={barcode || qValue} text={text} />
      {/* <div className="text-center">
        <button onClick={handleScreenshot}  className="btn btn-primary">
          Take Screenshot
        </button>
      </div> */}
    </div>
  );
};

export default PrintPage;