import React from "react";

const Printable = () => {
  return (
    <div className="card-body">
      <div style={{ fontSize: "1rem" }}>
        <h5 className="ms-2">Notes:</h5>
        <ul style={{ listStyle: 'none' }}>
          <li >
            1. Each visitor should submit separate registration form.
          </li>
          <li >
            2. Kindly upload the following
            <ul style={{ listStyle: 'none' }}>
              <li >
                A. Visitor photo in JPG/PDF format.
              </li>
              <li >
                B. Valid photo Id (Driving License / PAN Card / Election Card / Passport / Aadhar Card ) in PDF / JPG format.
              </li>
              {/* <li >
                C. Company GST certificate in PDF / JPG format.
              </li> */}

            </ul>
          </li>
          <li >
            3. Entry for visitors below 18 years are not allowed.

          </li>
          <li >
            4. Entry is only for B2B jewellery trade visitors. Inside the trade show, NO COUNTER SALES are permitted.
          </li>
          <li >5. Non-jewellers are not allowed.</li>
          <li >6. Incomplete form is unacceptable and will be rejected.</li>
          <li >7. GST Number Must Be Valid.</li>


        </ul>
      </div>
      {/* <div className="checkmark-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="120"
                  height="120"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="green"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="bi bi-check-circle"
                >
                  <circle cx="12" cy="12" r="10" />
                  <path d="M9 12l2 2 4-4" />
                </svg>
              </div> */}
    </div>
  );
};

export default Printable;
