import React, { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { Button, Modal, Spinner } from "react-bootstrap";

const CameraCapture = ({ setPhoto }) => {
  const webcamRef = useRef(null);
  const [showCamera, setShowCamera] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [file, setFile] = useState(null);
  const handleOpenCamera = (event) => {
    event.preventDefault(); // Prevent the default action
    setShowCamera(true);
  };

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
    const convertedFile = base64ToFile(imageSrc, 'captured_image.jpg');
    setPhoto(convertedFile); // Store the File object in state
    setShowCamera(false); // Optionally close the camera after capturing
  };

  const base64ToFile = (base64String, filename) => {
    const byteString = atob(base64String.split(',')[1]);
    const arrayBuffer = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      arrayBuffer[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
    return new File([blob], filename, { type: blob.type });
  };
  return (
    <div>
      <Button
        type="button"
        className="regis w-100 mt-2 .form-control"
        onClick={handleOpenCamera}
        style={{ background: 'rgb(255, 255, 255)', color: '#212529', border: '1px solid #dee2e6', borderRadius: '2px' }}

      >
        Open Camera
      </Button>

      <Modal
        show={showCamera}
        onHide={() => setShowCamera(false)}
        animation={false}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Capture Your Photo Here</Modal.Title>
        </Modal.Header>
        <Modal.Body> <div
        >
          <div>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={400}
              height={400}
              style={{ display: 'flex', margin: '0 auto' }}
              className='webcam'

            />
          </div>
          <div>
            <Button
              type="button"
              className="regis w-100 mt-2"
              onClick={handleCapture}
              style={{ backgroundColor: '#8d0035' }}


            >
              Capture Image
            </Button>
          </div>
        </div></Modal.Body>
      </Modal>

      {imageSrc && (
        <div>
          <img src={imageSrc} alt="Captured"
            className='image-preview'

          />
        </div>
      )}
    </div>
  );
};

export default CameraCapture;
