import React from 'react'
import CommonLogin from '../../common/CommonLogin'

const InventoryLogin = () => {
    return (
        <>
            <CommonLogin
                header={"Welcome to Inventory Area"}
                afterLogin={"/inventory-service/list"}
                loginFailed={"/inventory-service/login"}
                prem_name={"inventory_details"}
            />
        </>
    )
}

export default InventoryLogin
